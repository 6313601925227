<template>
  <nav class="nav">
    <Popover>
      <PopoverTrigger>
        <div class="nav__header">
          <img src="~/assets/images/logo-square-color.png" class="nav__header__logo" />
          <TextEllipsis class="nav__header__title"> {{ currentWorkspace?.name }} </TextEllipsis>
          <Icon icon="lucide:chevron-down" :size="14" class="nav__header__chevron" />
        </div>
      </PopoverTrigger>
      <PopoverContentWrapper side="bottom" :align-offset="8" :side-offset="-4">
        <div class="WorkspaceSwitcher">
          <div class="WorkspaceSwitcherSection" style="overflow: auto">
            <div
              v-for="workspace in workspaces"
              :key="workspace.id"
              class="WorkspaceSwitcherItem"
              @click="switchWorkspace(workspace)"
            >
              <img class="WorkspaceSwitcherItem__indicator" src="~/assets/images/logo-square-color.png" />
              <TextEllipsis>{{ workspace.name }}</TextEllipsis>
              <div v-if="currentWorkspace?.id === workspace.id" class="WorkspaceSwitcherItem__badge">
                <Icon icon="lucide:check" :size="10" :stroke-width="3" />
              </div>
            </div>
          </div>

          <div class="WorkspaceSwitcherSection">
            <NuxtLink to="/settings/members">
              <div class="WorkspaceSwitcherItem">
                <div class="WorkspaceSwitcherItem__indicator">
                  <Icon icon="lucide:users-round" />
                </div>
                <TextEllipsis>Gérer les membres</TextEllipsis>
              </div>
            </NuxtLink>
          </div>

          <div class="WorkspaceSwitcherSection">
            <NuxtLink to="/settings/account">
              <div v-if="teamMember" class="WorkspaceSwitcherItem">
                <TeamMemberAvatar :team-member="teamMember" />
                <TextEllipsis>{{ getFullName(teamMember) }}</TextEllipsis>
              </div>
            </NuxtLink>

            <NuxtLink to="/settings/workspace">
              <div class="WorkspaceSwitcherItem">
                <div class="WorkspaceSwitcherItem__indicator">
                  <Icon icon="lucide:settings" />
                </div>
                <TextEllipsis>Paramètres du workspace</TextEllipsis>
              </div>
            </NuxtLink>
          </div>

          <div class="WorkspaceSwitcherSection">
            <div class="WorkspaceSwitcherItem" @click="navigateTo('/logout')">
              <div class="WorkspaceSwitcherItem__indicator">
                <Icon icon="lucide:door-open" />
              </div>
              <TextEllipsis>Se déconnecter</TextEllipsis>
            </div>
          </div>
        </div>
      </PopoverContentWrapper>
    </Popover>

    <div
      style="
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 8px;
        padding-inline: 12px;
        gap: 8px;
      "
    >
      <NavSearch />
    </div>
    <div class="nav__content">
      <template v-for="(section, index) in navButtonSectionsFiltered" :key="section">
        <NavButtonGroup>
          <NavSection v-if="section.name">{{ section.name }}</NavSection>
          <template v-if="index === 0">
            <NavButton
              class="NavButton"
              :icon="mapIcon.notification"
              :is-active="isNotificationSheetOpened"
              @click="isNotificationSheetOpened = true"
            >
              <TextEllipsis> Notification{{ countUnread > 1 ? "s" : "" }} </TextEllipsis>
              <NotificationBadge :count="countUnread" style="margin-left: auto" />
            </NavButton>
            <LazyNotificationSheet v-if="isNotificationSheetOpened" @on-close="isNotificationSheetOpened = false" />
          </template>
          <template v-for="button in section.items" :key="button.to">
            <NavButton
              v-if="button.isParent"
              :icon="button.icon"
              is-parent
              :is-folded="button.isFolded"
              :is-active="button.isFolded && currentRoute.path.startsWith(button.to)"
              @click="button.trigger?.()"
            >
              <TextEllipsis> {{ button.label }} </TextEllipsis>
            </NavButton>

            <NuxtLink v-else v-slot="{ isActive }" :to="button.to">
              <NavButton
                :icon="button.icon"
                :is-active="isActive && !isNotificationSheetOpened"
                :is-child="button.isChild"
              >
                <TextEllipsis>
                  {{ button.label }}
                </TextEllipsis>
              </NavButton>
            </NuxtLink>
          </template>
        </NavButtonGroup>
        <NavSeparator />
      </template>

      <NuxtLink to="/logout" style="margin-top: auto">
        <NavButton :icon="mapIcon.logout"> Se déconnecter </NavButton>
      </NuxtLink>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { intersection } from "lodash-es";
import type { AppRole } from "@asap/shared";
import { getFullName } from "@asap/shared";
import { PopoverTrigger } from "radix-vue";
const { teamMember, userRoles, workspaces, switchWorkspace } = useUserMe();
const { isIndependentWorkspace, currentWorkspace } = useWorkspaceMe();

type NavButton = {
  to: string;
  icon?: string;
  label: string;
  roles: AppRole[];
  isHidden?: boolean;
  isChild?: boolean;
  isParent?: boolean;
  isFolded?: boolean;
  trigger?: () => void;
};

type NavButtonSection = { name: string; isHidden?: boolean; items: NavButton[] };

const { currentRoute } = useRouter();

const { countUnread } = useNotification();

const isNotificationSheetOpened = ref(false);

const isTalentFolded = ref(false);
const isDashboardFolded = ref(false);

const navButtonSections = computed<NavButtonSection[]>(() => [
  {
    name: "",
    items: [
      {
        to: "/dashboard",
        icon: mapIcon.dashboard_action_center,
        label: "Tableau de bord",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member"],
        isParent: true,
        isFolded: isDashboardFolded.value,
        trigger: () => {
          isDashboardFolded.value = !isDashboardFolded.value;
        },
      },
      {
        to: "/dashboard/action-center",
        icon: mapIcon.list_check,
        label: "Actions",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member"],
        isChild: true,
        isHidden: isDashboardFolded.value,
      },
      {
        to: "/dashboard/statistic",
        icon: mapIcon.statistics,
        label: "Statistiques",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member"],
        isChild: true,
        isHidden: isDashboardFolded.value,
      },
    ],
  },
  {
    name: "",
    isHidden: userRoles.value.includes("external_csm_team_member"),
    items: [
      {
        to: "/missions",
        icon: mapIcon.mission,
        label: "Missions",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member", "team_member_marketing"],
      },
      {
        to: "/talents",
        icon: mapIcon.talent,
        label: "Talents",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member", "team_member_marketing"],
        isParent: true,
        isFolded: isTalentFolded.value,
        trigger: () => {
          isTalentFolded.value = !isTalentFolded.value;
        },
      },
      {
        to: "/talents/list",
        icon: "lucide:logs",
        label: "CVthèque",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member", "team_member_marketing"],
        isChild: true,
        isHidden: isTalentFolded.value,
      },
      {
        to: "/talents/substitute-bench",
        icon: mapIcon.armchair,
        label: "Banc de touche",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member", "team_member_marketing"],
        isChild: true,
        isHidden: isTalentFolded.value,
      },
      {
        to: "/talents/search",
        icon: "lucide:scan-search",
        label: "Multi Search (alpha)",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member", "team_member_marketing"],
        isChild: true,
        isHidden: isTalentFolded.value,
      },
      {
        to: "/applications",
        icon: mapIcon.application,
        label: "Candidatures",
        roles: ["consultant_team_member", "team_member_marketing"],
      },
      {
        to: "/reference-checks",
        icon: mapIcon.check_circle,
        label: "Prises de références",
        roles: ["consultant_team_member"],
      },

      {
        to: "/contacts",
        icon: mapIcon.contact,
        label: "Contacts",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member", "team_member_marketing"],
      },
      {
        to: "/companies",
        icon: mapIcon.company,
        label: "Sociétés",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member", "team_member_marketing"],
      },
    ],
  },
  {
    name: "Suivi des missions",
    isHidden: userRoles.value.includes("external_csm_team_member"),
    items: [
      {
        to: "/contracts",
        icon: mapIcon.contract,
        label: "Contrats",
        roles: ["accounting_team_member", "csm_team_member", "consultant_team_member", "team_member_marketing"],
      },
      {
        to: "/amendments",
        icon: mapIcon.is_amendment,
        label: "Avenants",
        roles: ["accounting_team_member", "csm_team_member", "consultant_team_member"],
      },
      {
        to: "/planning",
        icon: mapIcon.planning,
        label: "Planning",
        roles: ["accounting_team_member", "csm_team_member", "consultant_team_member", "team_member_marketing"],
      },
      {
        to: "/timesheets",
        icon: mapIcon.timesheet_day,
        label: "Relevés d'heures",
        roles: ["accounting_team_member", "csm_team_member", "consultant_team_member"],
      },
      {
        to: "/recruitment-office-invoice-requests",
        icon: mapIcon.recruitment_office_invoice_request,
        label: "CAB",
        roles: ["accounting_team_member", "consultant_team_member"],
      },
    ],
  },
  {
    name: "Boite à outils",
    isHidden: userRoles.value.includes("external_csm_team_member"),
    items: [
      {
        to: "/calculator",
        icon: mapIcon.calculator,
        label: "Calculateur",
        roles: ["consultant_team_member", "csm_team_member", "accounting_team_member", "team_member_marketing"],
      },
      {
        to: "/commercial-conditions",
        icon: mapIcon.commercial_condition,
        label: "Conditions commerciales",
        roles: ["consultant_team_member"],
      },
      {
        to: "/independents",
        icon: mapIcon.independent,
        label: "Carte indépendants",
        roles: ["consultant_team_member", "accounting_team_member", "csm_team_member"],
      },
      {
        to: "/cooptation",
        icon: mapIcon.cooptation,
        isHidden: isIndependentWorkspace.value,
        label: "Cooptations",
        roles: ["accounting_team_member", "csm_team_member", "consultant_team_member"],
      },
      {
        to: "/convert",
        icon: mapIcon.image,
        label: "Convertir",
        roles: ["accounting_team_member", "csm_team_member", "consultant_team_member", "team_member_marketing"],
      },
    ],
  },
  {
    name: "Suivi des demandes",
    items: [
      {
        to: "/ppe-requests",
        icon: mapIcon.ppe,
        label: "EPI",
        roles: ["csm_team_member", "consultant_team_member"],
      },
      {
        to: "/definitive-end-requests",
        icon: mapIcon.is_definitive_end,
        label: "Fin de mission définitives",
        roles: ["accounting_team_member", "consultant_team_member", "csm_team_member"],
        isHidden: isIndependentWorkspace.value || userRoles.value.includes("external_csm_team_member"),
      },
      {
        to: "/work-accident-requests",
        icon: mapIcon.work_accident_request,
        label: "Accidents du travail",
        roles: ["csm_team_member", "consultant_team_member", "accounting_team_member"],
        isHidden: userRoles.value.includes("external_csm_team_member"),
      },
      {
        to: "/medical-checkup-requests",
        icon: mapIcon.medical_checkup_request,
        label: "Visites médicales",
        roles: ["csm_team_member", "consultant_team_member"],
      },

      {
        to: "/medical-centers",
        icon: mapIcon.medical_center,
        label: "Centres médicaux",
        roles: ["csm_team_member"],
        isHidden: userRoles.value.includes("external_csm_team_member"),
      },
      {
        to: "/professional-card-requests",
        icon: mapIcon.professional_card_request,
        label: "Cartes BTP",
        roles: ["accounting_team_member", "consultant_team_member", "csm_team_member"],
      },
      {
        to: "/advance-payment-requests/list",
        icon: mapIcon.advance_payment_request,
        label: "Acomptes salaires",
        roles: ["accounting_team_member", "csm_team_member", "consultant_team_member"],
        isHidden: userRoles.value.includes("external_csm_team_member"),
      },
      {
        to: "/qualification-training-requests",
        icon: mapIcon.qualification_training_request,
        label: "Habilitations",
        roles: ["accounting_team_member", "csm_team_member", "consultant_team_member"],
      },
      {
        to: "/qualification-centers",
        icon: mapIcon.school,
        label: "Centres de formation",
        roles: ["csm_team_member"],
        isHidden: userRoles.value.includes("external_csm_team_member"),
      },
      {
        to: "/expense-claim-requests",
        icon: mapIcon.expense_claim_request,
        label: "Notes de frais",
        roles: ["accounting_team_member", "consultant_team_member", "csm_team_member"],
        isHidden: userRoles.value.includes("external_csm_team_member"),
      },
      {
        to: "/independent-payment-requests",
        icon: mapIcon.independent_payment_request,
        label: "Factures",
        isHidden:
          (!isIndependentWorkspace.value && !userRoles.value.includes("accounting_team_member")) ||
          userRoles.value.includes("external_csm_team_member"),
        roles: ["accounting_team_member", "consultant_team_member"],
      },
      {
        to: "/company-insurance-requests",
        icon: mapIcon.company_insurance_request,
        label: "Garanties sociétés",
        roles: ["accounting_team_member", "consultant_team_member"],
        isHidden: userRoles.value.includes("external_csm_team_member"),
      },
    ],
  },
  {
    name: "Développeur",
    isHidden: !userRoles.value.some((role) =>
      ["team_member_developer", "team_member_independent_administrator"].includes(role)
    ),
    items: [
      {
        to: "/profile",
        icon: mapIcon.profile,
        label: "Profil",
        roles: ["team_member_developer"],
      },
      {
        to: "/users",
        icon: mapIcon.users,
        label: "Utilisateurs",
        roles: ["team_member_developer"],
      },
      {
        to: "/organigram",
        icon: mapIcon.network,
        label: "Organigramme",
        roles: ["team_member_developer", "team_member_independent_administrator"],
      },
    ],
  },
]);

const navButtonSectionsFiltered = computed(() =>
  navButtonSections.value
    .filter((section) => !section.isHidden)
    .map((section) => ({
      ...section,
      items: section.items
        .filter((item) => intersection(userRoles.value, item.roles).length)
        .filter((item) => !item.isHidden),
    }))
);
</script>

<style scoped lang="scss">
.nav {
  display: flex;
  flex-direction: column;
  width: 204px;
  background: var(--white);
  box-shadow: var(--shadow-0);
  flex-shrink: 0;
  transition: var(--transition-2);
  overflow: hidden;
  border-right: 1px solid var(--gray-6);

  &__header {
    display: flex;
    border-bottom: 1px solid var(--gray-6);
    align-items: center;
    padding-inline: 16px;
    gap: 8px;
    height: 48px;
    cursor: pointer;
    transition: var(--transition-0);

    &:hover {
      background: var(--gray-7);
    }

    &:active {
      background: var(--gray-6);
    }

    &__logo {
      height: 20px;
      width: 20px;
      border-radius: 5px;
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
    }

    &__chevron {
      margin-left: -4px;
      color: var(--gray-2);
      transition: var(--transition-1);

      :hover > & {
        transform: translateY(1px);
      }
    }
  }

  &__content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-block: 16px;
    padding-inline: 12px;
    flex: 1;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.WorkspaceSwitcher {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-6);
  border-radius: 10px;
  box-shadow: var(--shadow-3);
  width: 288px;
  margin-left: 8px;
  background: var(--white);
  max-height: calc(var(--radix-popover-content-available-height) - 32px);
}

.WorkspaceSwitcherSection {
  display: flex;
  flex-direction: column;
  padding: 8px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-6);
  }
}

.WorkspaceSwitcherItem {
  display: flex;
  align-items: center;
  height: 32px;
  padding-inline: 8px;
  gap: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: var(--transition-0);
  flex-shrink: 0;

  &:hover {
    background: var(--gray-6);
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    border-radius: 5px;
  }

  &__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 100px;
    background-color: var(--blue);
    margin-left: auto;
    color: var(--white);
    flex-shrink: 0;
  }
}
</style>
