<template>
  <button class="NavSearch" :class="{ isActive: showGlobalSearchModal }" @click="open('all')">
    <Icon :icon="mapIcon.search" class="NavSearchIcon" />

    Rechercher

    <!-- For now, both apple and microsoft devices have a command + K -->
    <ShortcutKey> {{ isAppleDevice ? "⌘K" : "⌘K" }} </ShortcutKey>
  </button>
</template>

<script setup lang="ts">
const { open, showGlobalSearchModal } = useGlobalSearch();

const isAppleDevice = computed(() => navigator.userAgent.includes("Mac OS X"));
</script>

<style scoped lang="scss">
.NavSearch {
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: var(--gray-2);
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  flex-shrink: 0;
  font-size: var(--size-14);
  font-weight: 500;
  border-radius: 10px;
  gap: 12px;
  cursor: pointer;
  border: 1px solid var(--gray-6);
  box-shadow: var(--shadow-0);

  &:hover {
    border: 1px solid var(--gray-5);
  }

  &:focus,
  &.isActive {
    border: 1px solid var(--blue);
    outline: 2px solid var(--blue-light);
  }
}

.NavSearchIcon {
  height: 18px;
  width: 18px;
  color: var(--gray-3);
}
</style>
