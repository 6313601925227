<template>
  <div class="main">
    <NavBar v-if="!$route.name?.toString().endsWith('talents-index-id')" style="grid-area: navbar" />

    <div style="grid-area: content; display: flex; overflow: hidden">
      <slot></slot>
    </div>

    <LazyGlobalSearchModal v-if="showGlobalSearchModal" @on-close="close()" />

    <LazyDialog v-if="showParasiteBugDialog">
      <DialogContent style="width: 384px; padding: 8px">
        <DialogCloseButton @click="showParasiteBugDialog = false" />
        <div class="gif" :style="`background-image: ${gifUrl};`"></div>
        <div style="display: flex; flex-direction: column; padding: 8px; gap: 16px">
          <DialogTitle style="margin-top: 16px">Nous avons repéré un bug. 🐛</DialogTitle>
          <div style="color: var(--gray-2); line-height: 20px">
            <span>
              Ce bug apparaît lorsque le back office est ouvert sur plusieurs onglets !
              <br />
              <br />
              Voici comment le résoudre:
            </span>

            <br />
            <br />

            <div style="font-weight: 500; line-height: 24px">👉 Solution 1:</div>
            Quittez tous les onglets liés au back office.

            <br />
            <br />

            <div style="font-weight: 500; line-height: 24px">👉 Solution 2:</div>
            Passez sur un
            <NuxtLink
              to="https://support.google.com/chrome/answer/95464?hl=fr"
              tabindex="-1"
              style="color: var(--blue)"
            >
              onglet de navigation privée.
            </NuxtLink>
          </div>

          <Button variant="outline" style="margin-top: 16px" @click="reloadNuxtApp()">
            <ButtonIcon icon="lucide:refresh-cw" />
            Actualiser la page
          </Button>
        </div>
      </DialogContent>
    </LazyDialog>
  </div>
</template>

<script setup lang="ts">
import DialogCloseButton from "~/components/ui/dialog/DialogCloseButton.vue";
import { sample } from "lodash-es";

const showParasiteBugDialog = ref(false);

const { showGlobalSearchModal, close } = useGlobalSearch();
const supabase = useSupabase();

const checkProtectRoute = async () => {
  const { status } = await fetch("/api/test/protect-route");

  return status >= 200 && status < 300;
};

/**
 * The auth cookies can take some time to be set after login
 * So we check if the user is logged in on the first render
 * If not, we refresh the session and check again
 * If the user is still not logged in, we show the parasite bug dialog
 * The refresh is necessary to refresh filestack too
 */
onMounted(async () => {
  const isFirstCheckOK = await checkProtectRoute();
  if (isFirstCheckOK) return;

  await supabase.auth.refreshSession();

  const isSecondCheckOk = await checkProtectRoute();
  if (isSecondCheckOk) return;

  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  // Si on a déjà refresh une fois, on a vraiment l'erreur
  if (searchParams.has("refresh")) {
    showParasiteBugDialog.value = true;
    throw new Error("Bug Parasite");
  }

  // Sinon on refresh la page
  searchParams.set("refresh", "1");
  window.location.assign(url.toString());
});

const gifs: string[] = [
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExemNnYnNzejJjbW9mZ2ZtZjhtbHZpOTQ0bDg4cHNqOWY2cGFjem9yaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ywvuMgCkPAAVAT2LRh/giphy-downsized.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZnpqZnVtNTA0MGRxYjllZ2c5ejE3bjdkNnozZ25iNHh5YnhicnhndCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/djIwU2iNbsF5lih2TV/giphy-downsized.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExN29jNDExdDg5YWNwOGJtd2gxNnJ6czB4aHdiODFlNzV1cXg4c3ZoYiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/MY3VG1UK03yK0QDlrB/giphy-downsized.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcmo5a2F2eXNhN2g5aXI3ZnBsdWZ4YWp6NjFzNHVtYXl4bjhwZW5pbiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/pFLvpV3wMMwaDgDHC2/giphy.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcnR1OG5udHJ4ZTdxbWE1ejgwd2Z1b3ZkY3NmYTU5dngxcWZ5bDltNCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/vjUWhKurpitmaVW4dQ/giphy.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcG9ydXNzNDM4dHV0NDNrOG16ZWV6Y2Zmd3p6eG1wNzZ2dW5la3lqZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/d4gRqSEQ2yDMRteXYt/giphy.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExeDBmZW5kdDg5cGp0Zm5pZTR3bmQyeHV1MzRiN2E1cXFueWs0N3U2eSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3Dqcxfi85xfEhdaNVV/giphy-downsized.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMWMzbmg1enoyZmVtYXlhazVyZnkydWpsc2ozNjV5NDk2Zjl0cTExdCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/UGNI2F5IK9QJdxI8ND/giphy-downsized.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExYXZyMXlxMHJob245dmh0cGo1dDNpb3g1NzU5ZDFpdjVsdmtydXFmbCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/iDao916122Cm3jNVvC/giphy-downsized.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExNm1qa2t6Njg0eHRwMzIwb2N1bjA1OWcyMGN1cm9kdTdtZjY2aWs5bCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/7dwhbnNicqMyQie4qN/giphy-downsized.gif",
  "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExYmhhZmJicGh5NWw4a3M1MXh1N2xsb2Zpbjk0cmtqYWJuNW1peXpxeiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/2Vuto7CotrpgAKMVoS/giphy.gif",
];

const gifUrl = `url("${sample(gifs)}")`;
</script>

<style scoped lang="scss">
.main {
  display: grid;
  grid-template-areas: "navbar content";
  grid-template-columns: auto 1fr;
  height: 100dvh;
  width: 100dvw;
  background: var(--gray-8);
  overflow: hidden;
}

.gif {
  background-size: cover;
  background-position: center;
  width: 100%;
  aspect-ratio: calc(5 / 3);
  // height: 320px;
  border-radius: 6px;
}
</style>
